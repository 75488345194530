/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1hjck2n2bh.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "topasPlusRest",
            "endpoint": "https://s9e2t78kx4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wmdyn6ktdjhb3oovuxzzcqi4y4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-afqrknj33rewrktqugxmywauwq",
    "aws_cognito_identity_pool_id": "eu-west-1:747a7efd-ff3b-4a1a-9661-e315175ba47e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_JwcTjfUT0",
    "aws_user_pools_web_client_id": "53keq0fpuhfqtidgk4plmv557b",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "topasplusfrontend-20201113131123-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://topasplusfrontend-20201113131123-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com",
    "aws_user_files_s3_bucket": "topasplusfrontend56811a89568e4711862065a850848b161737-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
